<template>
  <div class="staging">
    <label>
      {{ lang.metrics.filters.staging.label[languageSelected] }}
    </label>
    <vx-slider color="success" v-model="status" @change="change" class="mt-2">
      <span slot="on">
        {{ lang.metrics.filters.staging.show[languageSelected] }}
      </span>
      <span slot="off">
        {{ lang.metrics.filters.staging.hide[languageSelected] }}
      </span>
    </vx-slider>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StagingFilter',
  data() {
    return {
      status: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    change() {
      this.$emit('change', {
        type: 'staging',
        value: this.status
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.staging {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
}
</style>
